export function getArtworks() { 
  return artworks; 
}

export function findArtworkBySlug(slug){
  return artworks.findIndex((artwork) => (artwork.slug === slug));
}

export function artworkExists(slug){
  let artworkLoc = findArtworkBySlug(slug);
  if (artworkLoc === -1) { return false; }
  return true;
}

export function getArtworkBySlug(slug){
  let artworkLoc = findArtworkBySlug(slug);
  if (artworkLoc === -1) { return null; }
  return artworks[artworkLoc];
}

export function getRandomArtwork(){
  return artworks[Math.floor(Math.random() * artworks.length)];
}


const artworks = [
  {
    "hasNoId": true,
    "authorEn": "Julia Kudina",
    "slug": "phantasms",
    "titleEn": "Phantasms",
    "authorRu": "Юлия Кудина",
    "updated": 1653979782837,
    "price": 350000,
    "width": 52,
    "isSold": false,
    "materialEn": "Pencil on paper",
    "descriptionEn": "",
    "year": 2021,
    "id": 0,
    "material": "Бумага, карандаш / Pencil on paper",
    "seriesId": 2,
    "height": 78,
    "src": "phantasms",
    "titleRu": "Фантазмы",
    "materialRu": "Бумага, карандаш",
    "descriptionRu": "",
  },
  // {
  //   "id": 0,
  //   "authorEn": "Julia Kudina",
  //   "src": "russian-winter",
  //   "materialEn": "Oil on canvas",
  //   "descriptionRu": "",
  //   "updated": 1653977409481,
  //   "hasNoId": true,
  //   "slug": "russian-winter",
  //   "material": "Холст, масло / Oil on canvas",
  //   "seriesId": 10,
  //   "descriptionEn": "",
  //   "titleEn": "Russian Winter",
  //   "titleRu": "Русская зима",
  //   "width": 30,
  //   "height": 20,
  //   "price": 0,
  //   "isSold": true,
  //   "year": 2017,
  //   "authorRu": "Юлия Кудина",
  //   "materialRu": "Холст, масло",
  // },
  {
    "slug": "gold-twinkling",
    "price": 0,
    "titleRu": "Золотое Мерцание",
    "height": 90,
    "descriptionEn": "",
    "descriptionRu": "",
    "hasNoId": true,
    "updated": 1653977080281,
    "size": "90 x 100",
    "seriesId": 8,
    "materialEn": "Oil on canvas",
    "src": "gold-twinkling",
    "id": 0,
    "authorEn": "Julia Kudina",
    "materialRu": "Холст, масло",
    "authorRu": "Юлия Кудина",
    "width": 100,
    "isSold": true,
    "year": 2011,
    "titleEn": "Gold Twinkling",
    "material": "Холст, масло / Oil on canvas",
  },
  {
    "slug": "still-life-pumpking",
    "width": 80,
    "id": 0,
    "height": 70,
    "authorRu": "Юлия Кудина",
    "titleRu": "Натюрморт – Тыква",
    "hasNoId": true,
    "materialRu": "Холст, масло",
    "year": 2020,
    "descriptionRu": "",
    "titleEn": "Still Life – Pumpking",
    "price": 0,
    "authorEn": "Julia Kudina",
    "descriptionEn": "",
    "materialEn": "Oil on canvas",
    "isSold": true,
    "src": "pumpking-still-life2",
    "updated": 1653977126540,
    "material": "Холст, масло / Oil on canvas",
    "seriesId": 9,
  },
  {
    "height": 25,
    "materialRu": "Холст, масло",
    "seriesId": 4,
    "year": 2015,
    "authorRu": "Юлия Кудина",
    "hasNoId": true,
    "width": 30,
    "isSold": true,
    "price": 0,
    "slug": "spring-bouquet",
    "updated": 1653977158981,
    "descriptionEn": "",
    "src": "spring-bouquet",
    "size": "25 x 30",
    "titleEn": "Spring Bouquet",
    "materialEn": "Oil on canvas",
    "descriptionRu": "",
    "material": "Холст, масло / Oil on canvas",
    "titleRu": "Весенний букет",
    "id": 0,
    "authorEn": "Julia Kudina",
  },
  {
    "src": "pumpkin-still-life",
    "id": 22,
    "titleRu": "Натюрморт тыквы",
    "updated": 1653977448371,
    "material": "Холст, масло / Oil on canvas",
    "isSold": false,
    "titleEn": "Pumpkin's Still Life",
    "seriesId": 9,
    "materialEn": "Oil on canvas",
    "authorRu": "Юлия Кудина",
    "height": 70,
    "width": 80,
    "slug": "pumpkins-still-life",
    "materialRu": "Холст, масло",
    "authorEn": "Julia Kudina",
    "descriptionEn": "The still life is distinguished by a carefully thought-out composition. All objects are selected and arranged in such a way as to create a harmonious harmony of shape and color. The main character of the picture is a red juicy pumpkin, conveys the play of autumn colors. Still life has a depth of space, all objects are voluminous, material, dense.",
    "price": 50000,
    "hasNoId": false,
    "size": "70 x 80",
    "descriptionRu": "Натюрморт отличается тщательно продуманной композицией. Все предметы подобраны и расставлены так, чтобы создавать гармоничное созвучие формы и цвета. Главная героиня картины-рыжая сочная тыква, передает игру осенних красок. Натюрморт имеет глубину пространства, все предметы объемные, материальные, плотные.",
    "year": 2020,
  },
  {
    "size": "50 x 60",
    "width": 60,
    "titleRu": "Букет в фокусе",
    "year": 2017,
    "updated": 1653977478869,
    "descriptionEn": "This picture is a real apotheosis of summer. It depicts a huge bouquet of fresh garden flowers on a dark background, which allows you to attract more attention to the bouquet.",
    "descriptionRu": "Эта картина – настоящий апофеоз лета. На ней изображен огромный букет свежих садовых цветов темном фоне, что позволяет привлечь большее внимание к букету.",
    "titleEn": "Bouquet in Focus",
    "price": 40000,
    "isSold": false,
    "material": "Холст, масло / Oil on canvas",
    "src": "bouquet-in-focus",
    "seriesId": 4,
    "materialRu": "Холст, масло",
    "id": 23,
    "slug": "bouquet-in-focus",
    "authorEn": "Julia Kudina",
    "height": 50,
    "hasNoId": false,
    "authorRu": "Юлия Кудина",
    "materialEn": "Oil on canvas",
  },
  {
    "isSold": false,
    "material": "Холст, масло / Oil on canvas",
    "authorRu": "Юлия Кудина",
    "size": "40 x 65",
    "authorEn": "Julia Kudina",
    "titleRu": "Сирень",
    "height": 40,
    "updated": 1653978988722,
    "materialRu": "Холст, масло",
    "hasNoId": false,
    "descriptionRu": "Написана сочными, яркими красками. Объёмный рельеф мазков даёт ощущение максимального сходства с ароматными, пышными ветками сирени. Всё оттенки этого весеннего цветения смешались в единый вихрь фиолетовой мистерии!",
    "src": "lilac",
    "descriptionEn": "It is written in juicy, bright colors. The volumetric relief of the brushstrokes gives a feeling of maximum similarity with fragrant, lush branches of lilac. All the shades of this spring bloom mixed into a single vortex of purple mystery!",
    "titleEn": "Lilac",
    "materialEn": "Oil on canvas",
    "width": 65,
    "price": 40000,
    "year": 2015,
    "slug": "lilac",
    "seriesId": 4,
    "id": 24,
  },
  {
    "titleEn": "Bouquet",
    "src": "bouquet",
    "hasNoId": false,
    "authorEn": "Julia Kudina",
    "width": 70,
    "descriptionEn": "The flowers in the painting are painted with large expressive strokes. The elegant simplicity of the bouquet emphasizes the natural beauty of the plant. The painting is made on a bright yellow background, which immediately helps to return to sunny summer at any time of the year.",
    "year": 2017,
    "id": 26,
    "titleRu": "Букет",
    "descriptionRu": "Цветы на картине написаны крупными выразительными мазками. Изящная простота букета подчеркивает природную красоту растения. Картина выполнена на ярко-желтом фоне, что сразу помогает в любое время года вернуться в солнечное лето.",
    "updated": 1653979010054,
    "slug": "bouquet",
    "materialEn": "Oil on canvas",
    "seriesId": 4,
    "authorRu": "Юлия Кудина",
    "height": 50,
    "isSold": false,
    "size": "50 x 70",
    "price": 35000,
    "materialRu": "Холст, масло",
    "material": "Холст, масло / Oil on canvas",
  },
  {
    "slug": "thistle",
    "seriesId": 4,
    "width": 60,
    "height": 40,
    "materialEn": "Oil on canvas",
    "size": "40 x 60",
    "authorRu": "Юлия Кудина",
    "materialRu": "Холст, масло",
    "descriptionRu": "Есть что-то невероятно привлекательное в этом строгом, неприхотливом цветке и это очарование уловил художник! Чертополох на картине смотрится очень изысканно, каждая деталь прописана до мельчайших подробностей. Сдержанная цветовая гамма позволит этой работе идеально вписаться в любой интерьер.",
    "id": 28,
    "src": "thistle",
    "titleRu": "Чертополох",
    "isSold": false,
    "year": 2015,
    "hasNoId": false,
    "price": 30000,
    "descriptionEn": "There is something incredibly attractive in this strict, unpretentious flower and the artist caught this charm! The thistle on the canvas looks very exquisite, every detail is spelled out to the smallest detail. The restrained color scheme will allow this work to fit perfectly into any interior.",
    "titleEn": "Thistle",
    "material": "Холст, масло / Oil on canvas",
    "authorEn": "Julia Kudina",
    "updated": 1653979037081,
  },
  {
    "slug": "light-of-the-past",
    "price": 250000,
    "isSold": false,
    "descriptionEn": "The painting is painted in the technique of multi-layered painting, it is due to it that you get the feeling that the light comes from inside the painting and spreads a pleasant warmth in the soul...",
    "width": 100,
    "year": 2015,
    "titleRu": "Свет прошлых лет",
    "materialRu": "Холст, масло",
    "titleEn": "Light of the Past",
    "authorRu": "Юлия Кудина",
    "updated": 1653979603856,
    "size": "100 x 100",
    "materialEn": "Oil on canvas",
    "src": "light-of-the-past",
    "hasNoId": false,
    "seriesId": 1,
    "authorEn": "Julia Kudina",
    "height": 100,
    "material": "Холст, масло / Oil on canvas",
    "id": 30,
    "descriptionRu": "Картина написана в технике многослойной живописи, именно за счет нее у вас появляется чувство, что свет идет изнутри картины и разливается приятным теплом в душе...",
  },
  {
    "width": 60,
    "height": 80,
    "price": 200000,
    "authorRu": "Юлия Кудина",
    "descriptionEn": "Who doesn't wonder what his city looked like 100 years ago? Looking at the picture plunges into the past... It feels like the building is shining like gold.",
    "updated": 1653979562100,
    "materialRu": "Холст, масло",
    "titleEn": "Shining Light",
    "src": "shining-light",
    "slug": "shining-light",
    "material": "Холст, масло / Oil on canvas",
    "hasNoId": false,
    "size": "80 x 60",
    "titleRu": "Сияние света",
    "seriesId": 1,
    "id": 31,
    "materialEn": "Oil on canvas",
    "year": 2020,
    "authorEn": "Julia Kudina",
    "isSold": false,
    "descriptionRu": "Кому не интересно, как выглядел его город 100 лет назад? Глядя на картину погружаешься в прошлое... Такое ощущение, что здание сияет словно золото.",
  },
  {
    "hasNoId": false,
    "materialEn": "Oil on canvas",
    "price": 25000,
    "titleRu": "Тюльпаны",
    "materialRu": "Холст, масло",
    "width": 60,
    "descriptionRu": "Энергия весны... яркие сочные радостные цвета притягивают взгляд. Чем дольше смотришь, тем больше становятся видны изящные детали, делающие эту работу уникальной.",
    "height": 40,
    "id": 32,
    "descriptionEn": "The energy of spring... bright juicy joyful colors attract the eye. The longer you look, the more elegant details that make this work unique become visible.",
    "isSold": false,
    "authorEn": "Julia Kudina",
    "size": "40 x 60",
    "titleEn": "Tulips",
    "updated": 1653979528102,
    "seriesId": 4,
    "src": "tulips",
    "year": 2015,
    "slug": "tulips",
    "material": "Холст, масло / Oil on canvas",
    "authorRu": "Юлия Кудина",
  },
  {
    "isSold": false,
    "seriesId": 7,
    "authorEn": "Julia Kudina",
    "height": 60,
    "materialRu": "Холст, масло",
    "descriptionRu": "Автор передает свое настроение \"здесь и сейчас\". Прекрасная работа, которая впишется в любой интерьер. ",
    "titleEn": "Improvisation",
    "titleRu": "Импровизация",
    "id": 34,
    "year": 2012,
    "updated": 1653979505779,
    "descriptionEn": "The author conveys his mood \"here and now\". A wonderful work that will fit into any interior.",
    "src": "improvisation",
    "authorRu": "Юлия Кудина",
    "materialEn": "Oil on canvas",
    "price": 90000,
    "hasNoId": false,
    "material": "Холст, масло / Oil on canvas",
    "slug": "improvisation",
    "size": "60 x 80",
    "width": 80,
  },
  {
    "price": 350000,
    "isSold": false,
    "id": 37,
    "authorRu": "Юлия Кудина",
    "height": 62,
    "authorEn": "Julia Kudina",
    "slug": "sunflowers",
    "src": "sunflowers",
    "materialRu": "Бумага, карандаш",
    "seriesId": 2,
    "materialEn": "Pencil on paper",
    "titleRu": "Подсолнухи",
    "year": 2015,
    "descriptionEn": "You're looking at it – you won't get enough of it. The sunflower is a big and beautiful flower. The middle of it is black, and the petals are yellow, like the Sun. No wonder he was given such a name. The sunflower is also popularly called the «Flower of the Sun».",
    "descriptionRu": "Смотришь на него – не налюбуешься. Подсолнух – большой и красивый цветок. Серединка у него черная, а лепестки желтые, как солнышко. Недаром ему дали такое название. В народе подсолнух ещё называют «Цветком Солнца».",
    "size": "62 x 93",
    "updated": 1653788167794,
    "titleEn": "Sunflowers",
    "material": "Бумага, карандаш / Pencil on paper",
    "width": 93,
  },
  {
    "authorRu": "Юлия Кудина",
    "id": 38,
    "isSold": false,
    "updated": 1653979478415,
    "src": "mirage",
    "descriptionEn": "A mirage is a vision, a ghost. Such a natural phenomenon is unique and absolutely unpredictable. The artist was able to catch with a wave of his hand and hands the refraction of light streams at the boundary between sharply different layers of air in density and temperature. The painting has a magical glow! If you look closely, you can see a shell. And what do you see?",
    "price": 85000,
    "authorEn": "Julia Kudina",
    "material": "Холст, масло / Oil on canvas",
    "width": 100,
    "descriptionRu": "Мираж - это видение, призрак. Такое природное явление уникальное и абсолютно непредсказуемое. Художник смог уловить взмахом руки и кистей преломление потоков света на границе между резко различными по плотности и температуре слоями воздуха. Картина имеет магическое свечение! Если присмотреться, то можно увидеть ракушку. А что видите вы?",
    "year": 2014,
    "seriesId": 8,
    "height": 90,
    "titleRu": "Мираж",
    "materialEn": "Oil on canvas",
    "size": "90 x 100",
    "slug": "mirage",
    "hasNoId": false,
    "materialRu": "Холст, масло",
    "titleEn": "Mirage",
  },
  {
    "size": "59 x 59",
    "width": 59,
    "descriptionEn": "The still life «Scarlet Roses» radiates a kind of tension, sharpness and sensuality.",
    "id": 40,
    "isSold": false,
    "material": "Холст, масло / Oil on canvas",
    "hasNoId": false,
    "descriptionRu": "Натюрморт «Алые розы» излучает своеобразное напряжение, остроту и чувственность.",
    "slug": "scarlet-roses",
    "price": 35000,
    "updated": 1653979449771,
    "year": 2012,
    "src": "scarlet-roses",
    "authorRu": "Юлия Кудина",
    "titleRu": "Алые розы",
    "height": 59,
    "titleEn": "Scarlet Roses",
    "materialEn": "Oil on canvas",
    "authorEn": "Julia Kudina",
    "seriesId": 4,
    "materialRu": "Холст, масло",
  },
  {
    "materialEn": "Oil on canvas",
    "updated": 1653976372322,
    "seriesId": 3,
    "year": 2012,
    "width": 100,
    "authorRu": "Юлия Кудина",
    "height": 90,
    "descriptionEn": "A very original work, not everyone notices the figure of a girl lying in irises at first sight. This is an interesting compositional solution in which the main object (the girl) is not in the foreground but...",
    "material": "Холст, масло / Oil on canvas",
    "titleRu": "Ирисы – Цветы любви",
    "size": "90 x 100",
    "isSold": true,
    "materialRu": "Холст, масло",
    "src": "irises-flowers-of-love",
    "price": 0,
    "id": 42,
    "titleEn": "Irises – Flowers of Love",
    "descriptionRu": "Очень оригинальная работа, ведь не каждый с первого взглада замечает фигуру девушки, лежащую в ирисах. Это интересное композиционноен решение, в котром главный объект (девушка) не на первом плане, но...",
    "hasNoId": false,
    "slug": "irises-flowers-of-love",
    "authorEn": "Julia Kudina",
  },
  {
    "height": 100,
    "material": "Холст, масло / Oil on canvas",
    "titleRu": "Карибская красавица",
    "descriptionEn": "Incredibly bright, juicy work, which depicts a decorative silhouette of a shell. There are no convex textured strokes in this picture. The painting is painted in a technique in which the brush is practically not used.",
    "materialRu": "Холст, масло",
    "price": 100000,
    "titleEn": "Caribbean Beauty",
    "id": 43,
    "seriesId": 8,
    "year": 2012,
    "authorEn": "Julia Kudina",
    "isSold": false,
    "hasNoId": false,
    "slug": "caribbean-beauty",
    "materialEn": "Oil on canvas",
    "updated": 1653979415519,
    "authorRu": "Юлия Кудина",
    "descriptionRu": "Невероятно яркая, сочная работа, на которой изображен декоративный силуэт ракушки. На этой картине отсутствуют выпуклые фактурные мазки. Написана картина в технике, при которой кисть практически не используется.",
    "size": "100х120",
    "width": 120,
    "src": "caribbean-beauty",
  },
  {
    "titleRu": "Абхазский натюрморт",
    "src": "abkhazian-still-life",
    "materialRu": "Бумага, карандаш",
    "authorRu": "Юлия Кудина",
    "updated": 1653979063392,
    "isSold": true,
    "width": 100,
    "size": "80 x 100",
    "id": 47,
    "titleEn": "Abkhazian Still Life",
    "hasNoId": false,
    "year": 2012,
    "materialEn": "Pencil on paper",
    "slug": "abkhazian-still-life",
    "authorEn": "Julia Kudina",
    "height": 80,
    "material": "Бумага, карандаш / Pencil on paper",
    "seriesId": 2,
    "descriptionRu": "",
    "price": 0,
    "descriptionEn": "",
  },
  {
    "id": 48,
    "titleEn": "Kuban Still Life",
    "height": 80,
    "hasNoId": false,
    "seriesId": 9,
    "updated": 1653979362743,
    "src": "kuban-still-life",
    "slug": "kuban-still-life",
    "year": 2012,
    "descriptionRu": "",
    "descriptionEn": "",
    "titleRu": "Кубанский натюрморт",
    "material": "Холст, масло / Oil on canvas",
    "authorRu": "Юлия Кудина",
    "authorEn": "Julia Kudina",
    "width": 100,
    "materialEn": "Oil on canvas",
    "size": "80 x 100",
    "materialRu": "Холст, масло",
    "price": 280000,
    "isSold": false,
  },
  {
    "materialEn": "Oil on canvas",
    "updated": 1653979333887,
    "isSold": false,
    "year": 2012,
    "size": "80 x 100",
    "descriptionRu": "Изображе­ние рыбы для христианина было своего рода визуальным воплощением глав­ной бого­словской идеи христианства. Магическая рыба! Если приглядеться, то вместо рыбы можно увидеть сову.",
    "height": 80,
    "hasNoId": false,
    "src": "ichthis",
    "price": 150000,
    "width": 100,
    "authorEn": "Julia Kudina",
    "authorRu": "Юлия Кудина",
    "seriesId": 5,
    "descriptionEn": "The image of a fish for a Christian was a kind of visual embodiment of the main theological idea of Christianity. Magic fish! If you look closely, you can see an owl instead of a fish.",
    "material": "Холст, масло / Oil on canvas",
    "materialRu": "Холст, масло",
    "titleRu": "Ихтис",
    "titleEn": "Ichthis",
    "slug": "ichthis",
    "id": 49,
  },
  {
    "titleEn": "Selena",
    "hasNoId": false,
    "updated": 1653979303338,
    "year": 2012,
    "height": 95,
    "authorEn": "Julia Kudina",
    "src": "selena",
    "size": "95 x 120",
    "slug": "selena",
    "material": "Холст, масло / Oil on canvas",
    "materialRu": "Холст, масло",
    "price": 230000,
    "descriptionRu": "Ракушки, выброшенные на берег, обласканные луной... мистика подводного мира",
    "width": 120,
    "id": 50,
    "authorRu": "Юлия Кудина",
    "titleRu": "Селена",
    "isSold": false,
    "descriptionEn": "Shells washed ashore, caressed by the moon... mysticism of the underwater world",
    "materialEn": "Oil on canvas",
    "seriesId": 8,
  },
  {
    "updated": 1653979265841,
    "src": "evening",
    "hasNoId": false,
    "isSold": false,
    "id": 51,
    "materialRu": "Холст, масло",
    "authorRu": "Юлия Кудина",
    "authorEn": "Julia Kudina",
    "slug": "evening",
    "width": 56,
    "materialEn": "Oil on canvas",
    "height": 100,
    "seriesId": 1,
    "titleEn": "Evening",
    "material": "Холст, масло / Oil on canvas",
    "year": 2015,
    "descriptionRu": "Картина выполнена маслом на холсте и поражает взгляд зрителя мощной энергетикой. Она как-будто погружает назад в прошлое. В жилом доме спокойно и безмятежно спят люди, лишь свет фонаря заглядывает к ним в окна.",
    "titleRu": "Вечер",
    "price": 500000,
    "descriptionEn": "The painting is made in oil on canvas and strikes the viewer's eye with powerful energy. It's like she's plunging back into the past. In a living house, people sleep peacefully and serenely, only the light of a lantern looks into their windows.",
    "size": "100 x 70",
  },
  {
    "materialEn": "Oil on canvas",
    "year": 2017,
    "id": 52,
    "price": 380000,
    "size": "120 x 160",
    "titleEn": "Autum Fruits",
    "descriptionRu": "В этой картине соединены пейзаж и натюрморт одновременно. Плоды золотой осени дарят возможность полюбоваться последними лакомствами теплого времени и еще раз поразиться количеству оттенков на картине.",
    "authorEn": "Julia Kudina",
    "materialRu": "Холст, масло",
    "updated": 1653979234197,
    "height": 120,
    "seriesId": 9,
    "authorRu": "Юлия Кудина",
    "material": "Холст, масло / Oil on canvas",
    "width": 160,
    "hasNoId": false,
    "descriptionEn": "This painting combines landscape and still life at the same time. The fruits of the golden autumn give you the opportunity to admire the latest delicacies of the warm time and once again marvel at the number of shades in the picture.",
    "isSold": false,
    "src": "autum-fruits",
    "titleRu": "Плоды осени",
    "slug": "autum-fruits",
  },
  {
    "material": "Холст, масло / Oil on canvas",
    "titleRu": "Копченая рыба",
    "price": 70000,
    "descriptionEn": "On a warm summer morning at the Haymarket in the fish rows, the aroma of smoked fish attracts the first buyers...",
    "seriesId": 5,
    "year": 2015,
    "authorEn": "Julia Kudina",
    "isSold": false,
    "src": "smoked-fish",
    "materialRu": "Холст, масло",
    "slug": "smoked-fish",
    "hasNoId": false,
    "id": 57,
    "width": 100,
    "height": 70,
    "materialEn": "Oil on canvas",
    "updated": 1653979182151,
    "authorRu": "Юлия Кудина",
    "size": "70 x 100",
    "titleEn": "Smoked Fish",
    "descriptionRu": "Теплым летним утром на Сенном рынке в рыбных рядах аромат копченой рыбы манит к себе первых покупателей...",
  },
  {
    "id": 58,
    "updated": 1653979159386,
    "price": 250000,
    "materialRu": "Холст, масло",
    "isSold": false,
    "height": 160,
    "titleRu": "Знак рыбы",
    "titleEn": "Pisces Sign",
    "descriptionRu": "В астрологии знак Рыбы считается самым мистичным. Представленная картина также полна загадок. Часть холста занимает голова рыбы. Голова - это разум, рациональность. Ниже располагается то, что скрыто в дебрях бессознательного. Подсолнух оборачивается лицом, связка перца-зародышами, головки чеснока - подводными обитателями-медузами... Картина Знак Рыбы - дверь в собственное подсознание... Хотите пройти по его лабиринтам?",
    "hasNoId": false,
    "src": "fish-symbol",
    "width": 120,
    "authorRu": "Юлия Кудина",
    "materialEn": "Oil on canvas",
    "seriesId": 5,
    "year": 2015,
    "size": "160 x 120",
    "authorEn": "Julia Kudina",
    "descriptionEn": "In astrology, the Pisces sign is considered the most mystical. The presented picture is also full of riddles. Part of the canvas is occupied by the head of a fish. The head is the mind, rationality. Below is what is hidden in the wilds of the unconscious. A sunflower turns into a face, a bunch of pepper-embryos, garlic heads - underwater inhabitants-jellyfish... The picture of the Sign of Pisces is the door to your own subconscious... Do you want to go through its labyrinths?",
    "slug": "pisces-sign",
    "material": "Холст, масло / Oil on canvas",
  },
  {
    "materialRu": "Холст, масло",
    "id": 59,
    "authorEn": "Julia Kudina",
    "titleRu": "Голова толстолобика",
    "isSold": false,
    "src": "silver-carp-head",
    "width": 100,
    "price": 90000,
    "seriesId": 5,
    "hasNoId": false,
    "height": 80,
    "year": 2015,
    "slug": "silver-carp-head",
    "titleEn": "Silver Carp Head",
    "authorRu": "Юлия Кудина",
    "size": "80 x 100",
    "descriptionRu": "Перед нами улов рыбака: раки и рыба. Эти предметы находятся на переднем плане. Казалось бы, что может быть проще. Но если взглянуть получше и включить свою фантазию, то голова толстолобика в миг превращается в бабочку. А может это сова залетела в гости? Или вовсе почки и улов касается охотника?",
    "material": "Холст, масло / Oil on canvas",
    "materialEn": "Oil on canvas",
    "updated": 1653979096532,
    "descriptionEn": "Before us is a fisherman's catch: crayfish and fish. These items are in the foreground. It would seem that it could be easier. But if you take a closer look and turn on your imagination, then the head of a silver carp turns into a butterfly in an instant. Or maybe it's an owl that flew in to visit? Or does the kidneys and the catch concern the hunter at all?",
  },
  {
    "src": "golden-fish",
    "isSold": false,
    "material": "Холст, масло / Oil on canvas",
    "id": 63,
    "price": 40000,
    "width": 60,
    "authorRu": "Юлия Кудина",
    "materialRu": "Холст, масло",
    "materialEn": "Oil on canvas",
    "hasNoId": false,
    "titleRu": "Золотая рыба",
    "descriptionEn": "",
    "year": 2016,
    "updated": 1653978947223,
    "descriptionRu": "",
    "height": 40,
    "slug": "golden-fish",
    "titleEn": "Golden Fish",
    "authorEn": "Julia Kudina",
    "seriesId": 5,
    "size": "40 x 60",
  },
  {
    "authorEn": "Julia Kudina",
    "titleRu": "Тайны океана",
    "descriptionEn": "Sea shells represent one of the most elegant and interesting forms in nature. In ancient times, seashells were a talisman of travelers, a symbol of good luck, femininity, fertility, and like any living being, it is a soul that personifies and radiates a lot of positive energy. As a gift, such a sea beauty is suitable for lovers of the sea, realistic painting, travel, symbolism.",
    "height": 70,
    "materialEn": "Oil on canvas",
    "hasNoId": false,
    "seriesId": 8,
    "authorRu": "Юлия Кудина",
    "isSold": false,
    "slug": "ocean-secrets",
    "id": 64,
    "year": 2012,
    "updated": 1653978910078,
    "size": "70 x 80",
    "titleEn": "Ocean Secrets",
    "materialRu": "Холст, масло",
    "price": 100000,
    "width": 80,
    "material": "Холст, масло / Oil on canvas",
    "src": "ocean-secrets",
    "descriptionRu": "Морские раковины представляют из себя одну из самых элегантных и интересных форм в природе. В далекие времена ракушки были талисманом путешественников, символом удачи, женственности, плодородия, и как любое живое существо – это душа, олицетворяющая и излучающая много позитивной энергии. В качестве подарка такая морская красавица подойдет для любителей моря, реалистической живописи, путешествий, символизма.",
  },
  {
    "materialEn": "Oil on canvas",
    "updated": 1653978889121,
    "size": "100 x 200",
    "authorEn": "Julia Kudina",
    "price": 480000,
    "material": "Холст, масло / Oil on canvas",
    "descriptionEn": "The painting was painted using the techniques of the old masters of the XVII century, which create an indescribable effect of the play of light and shadow. «Old Town Ekaterinodar» – classical techniques in a modern interpretation! The painting is filled with light; it itself is its source... The facade of the building, snatched out of the darkness by a soft glow... The walls of houses dissolving in the twilight... All this resembles fabulous scenery for a magical performance that is ready to play out in front of a true connoisseur of painting...",
    "authorRu": "Юлия Кудина",
    "seriesId": 1,
    "materialRu": "Холст, масло",
    "hasNoId": false,
    "year": 2015,
    "slug": "old-town-ekaterinodar",
    "isSold": false,
    "height": 100,
    "src": "old-town-ekaterinodar",
    "titleEn": "Old Town Ekaterinodar",
    "width": 200,
    "titleRu": "Старый Екатеринодар",
    "descriptionRu": "Картина написана с использованием техник старых мастеров XVII века, которые создают непередаваемый эффект игры света и тени. «Старый Екатеринодар» – классические приемы в современной интерпретации! Картина наполнена светом; она сама является его источником... Фасад здания, выхваченный из темноты мягким свечением... Растворяющиеся в сумерках стены домов... Все это напоминает сказочные декорации к волшебному спектаклю, который готов разыграться перед истинным ценителем живописи...",
    "id": 66,
  },
  {
    "titleEn": "Secrets of the Midnight Town",
    "authorEn": "Julia Kudina",
    "seriesId": 1,
    "year": 2015,
    "height": 80,
    "updated": 1653978825552,
    "authorRu": "Юлия Кудина",
    "materialEn": "Oil on canvas",
    "width": 100,
    "descriptionEn": "Marvelous Ekaterinodar is a hymn to antiquity and disappearing beauty. The fascinating mysticism of the night city both frightens and attracts at the same time…",
    "isSold": false,
    "slug": "secrets-of-the-midnight-town",
    "hasNoId": false,
    "size": "80 x 100",
    "price": 400000,
    "src": "secrets-of-the-night-town",
    "titleRu": "Тайны ночного города",
    "material": "Холст, масло / Oil on canvas",
    "materialRu": "Холст, масло",
    "descriptionRu": "Чудный Екатеринодар – гимн старине и исчезающей красоте. Завораживающая мистика ночного города и пугает и притягивает одновременно…",
    "id": 67,
  },
  {
    "titleEn": "Nymphs",
    "slug": "nymphs",
    "materialRu": "Холст, масло",
    "authorEn": "Julia Kudina",
    "authorRu": "Юлия Кудина",
    "titleRu": "Нимфы",
    "updated": 1653978787783,
    "src": "nymphs",
    "isSold": false,
    "height": 40,
    "descriptionEn": "The coolness of summer.. delicate emerald green of foliage, graceful shadows of beautiful girls are spotted among the trees...The picture is interesting for its understatement, as if the artist specifically leaves us the opportunity to guess what is happening.",
    "size": "40 x 60",
    "width": 60,
    "id": 69,
    "price": 30000,
    "descriptionRu": "Влажная прохлада лета.. нежная изумрудная зелень листвы, среди деревьев расположились изящные силуэты прекрасных девушек... Картина интересна сворей недосказанностью, как будто автор специально оставляет нам возмржность домыслить происходящее.",
    "material": "Холст, масло / Oil on canvas",
    "hasNoId": false,
    "seriesId": 3,
    "year": 2018,
    "materialEn": "Oil on canvas",
  },
  {
    "price": 350000,
    "materialEn": "Oil on canvas",
    "materialRu": "Холст, масло",
    "hasNoId": false,
    "src": "evening-over-the-farm",
    "descriptionRu": "Сгустились сумерки, взошла огромная желтая луна... Пыльных воздух остывает, внем разлит аромат последних осенних цветов, спелой тыквы, трав... ",
    "titleRu": "Вечер над хутором",
    "material": "Холст, масло / Oil on canvas",
    "seriesId": 9,
    "year": 2016,
    "authorRu": "Юлия Кудина",
    "slug": "evening-over-the-farm",
    "width": 160,
    "isSold": false,
    "height": 120,
    "titleEn": "Evening over the Farm",
    "authorEn": "Julia Kudina",
    "updated": 1653978751055,
    "size": "120 x 160",
    "descriptionEn": "Dusk deepened, a huge yellow moon rose... The dusty air cools down, the aroma of the last autumn flowers, ripe pumpkins, herbs is poured out...",
    "id": 71,
  },
  {
    "height": 80,
    "materialRu": "Бумага, карандаш",
    "isSold": false,
    "authorRu": "Юлия Кудина",
    "descriptionEn": "The painting is painted in the technique of \"Hypergraphy\" from nature. Every piece, every minute detail was carefully and slowly drawn. Such a picture can be viewed endlessly. It will undoubtedly become a stylish accent in your interior and will always catch the eye.",
    "descriptionRu": "Картина написана в технике «Гиперграфика» с натуры. Тщательно и неспешно прорисовывался каждый кусочек, каждая мельчайшая деталь. Такую картину можно рассматривать бесконечно. Она несомненно станет стильным акцентом в вашем интерьере и всегда будет приковывать взгляд.",
    "titleRu": "Кубанский натюрморт (Графика)",
    "width": 100,
    "titleEn": "Kuban Still Life (Graphic)",
    "src": "kuban-still-life-graphic",
    "materialEn": "Pencil on paper",
    "seriesId": 2,
    "id": 72,
    "hasNoId": false,
    "authorEn": "Julia Kudina",
    "material": "Бумага, карандаш / Pencil on paper",
    "size": "80 x 100",
    "slug": "kuban-still-life-graphic",
    "price": 150000,
    "updated": 1653978632608,
    "year": 2012,
  },
  {
    "titleEn": "Field Bouquet",
    "year": 2019,
    "titleRu": "Полевой букет",
    "descriptionRu": "Фактура рисунка создавалась с помощью мастихина - инструмента для нанесения краски. Темный фон усиливает контрастность букета и светлые цветы смотрятся ярко и сочно Такая картина освежит любой интерьер.",
    "updated": 1653978441670,
    "authorEn": "Julia Kudina",
    "authorRu": "Юлия Кудина",
    "descriptionEn": "The texture of the drawing was created using a palette knife - a tool for applying paint. The dark background enhances the contrast of the bouquet and the light flowers look bright and juicy, such a picture will refresh any interior.",
    "price": 25000,
    "width": 50,
    "src": "field-bouquet",
    "slug": "field-bouquet",
    "hasNoId": false,
    "size": "50 x 50",
    "height": 50,
    "material": "Холст, масло / Oil on canvas",
    "materialRu": "Холст, масло",
    "seriesId": 4,
    "id": 74,
    "materialEn": "Oil on canvas",
    "isSold": false,
  },
  {
    "titleRu": "Груша",
    "updated": 1653978421139,
    "authorRu": "Юлия Кудина",
    "descriptionRu": "Свежесть и яркость с стиле прованс дополнит в вашем интерьере \"Груша\".",
    "id": 77,
    "size": "20 x 26",
    "src": "pear",
    "titleEn": "Pear",
    "slug": "pear",
    "seriesId": 6,
    "width": 26,
    "isSold": false,
    "material": "Холст, масло / Oil on canvas",
    "year": 2019,
    "height": 20,
    "materialRu": "Холст, масло",
    "materialEn": "Oil on canvas",
    "hasNoId": false,
    "authorEn": "Julia Kudina",
    "descriptionEn": "Freshness and brightness with Provence style will complement \"Pear\" in your interior.",
    "price": 4000,
  },
  // {
  //   "year": 2019,
  //   "size": "20 x 30",
  //   "titleEn": "Winter Mountains",
  //   "updated": 1653978401748,
  //   "descriptionRu": "Живописные горы, лазурный небосвод и завораживающее сияние пейзажных зеркал, которые не могут не радовать человеческие глаза. Захватывает дух! Картина излучает свежесть горного чистого воздуха и энергию зимней сказки",
  //   "src": "winter-mountains",
  //   "descriptionEn": "Beautiful mountains, blue sky and mesmerizing glow of landscape mirrors that delight human eyes. It is breathtaking! You can feel the freshness of mountain clean air and the energy of a winter fairy tale.",
  //   "authorRu": "Юлия Кудина",
  //   "isSold": false,
  //   "width": 30,
  //   "price": 20000,
  //   "material": "Холст, масло / Oil on canvas",
  //   "materialRu": "Холст, масло",
  //   "titleRu": "Снежные горы",
  //   "id": 78,
  //   "authorEn": "Julia Kudina",
  //   "slug": "winter-mountains",
  //   "hasNoId": false,
  //   "height": 20,
  //   "materialEn": "Oil on canvas",
  //   "seriesId": 10,
  // },
  {
    "titleRu": "Лимоны",
    "width": 15,
    "material": "Холст, масло / Oil on canvas",
    "size": "20 x 15",
    "descriptionRu": "Яркие, сочные лимоны на темном фоне, так и хочется рассматривать каждый мазок. Идеальная картина для любого интерьера.",
    "slug": "lemons",
    "authorRu": "Юлия Кудина",
    "titleEn": "Lemons",
    "materialRu": "Холст, масло",
    "id": 79,
    "hasNoId": false,
    "price": 0,
    "height": 20,
    "materialEn": "Oil on canvas",
    "updated": 1653978370105,
    "isSold": true,
    "src": "lemons",
    "authorEn": "Julia Kudina",
    "seriesId": 6,
    "year": 2019,
    "descriptionEn": "Bright, juicy lemons on a dark background, which makes you want to consider every stroke. The perfect picture for any interior.",
  },
  {
    "materialEn": "Oil on canvas",
    "isSold": true,
    "descriptionRu": "Идеальное дополнение к классическому интерьеру, кантри или модерну в доме идеально дополнит изысканный и вкусный \"Гранат\".",
    "titleEn": "Pomegranate",
    "authorEn": "Julia Kudina",
    "titleRu": "Гранат",
    "width": 15,
    "year": 2019,
    "slug": "pomegranate",
    "descriptionEn": "The perfect addition to the classic interior, country or modern in the house will perfectly complement the exquisite and delicious \"Pomegranate\".",
    "authorRu": "Юлия Кудина",
    "seriesId": 6,
    "updated": 1653978345067,
    "src": "pomegranate",
    "hasNoId": false,
    "size": "20 x 15",
    "id": 80,
    "height": 20,
    "material": "Холст, масло / Oil on canvas",
    "materialRu": "Холст, масло",
    "price": 0,
  },
  {
    "year": 2019,
    "slug": "apple",
    "descriptionRu": "Сочный и такой обыденный фрукт, яблоко в изобразительном искусстве — объект парадоксальный. Это таинственный и неоднозначный символ, раскрывающий массу значений и объединяющий множество противоположных смыслов. Уже много веков оно олицетворяет как добро, так и зло.",
    "price": 4000,
    "width": 15,
    "seriesId": 6,
    "materialEn": "Oil on canvas",
    "descriptionEn": "Juicy and such an ordinary fruit, an apple in fine art is a paradoxical object. It is a mysterious and ambiguous symbol that reveals a lot of meanings and unites many opposite meanings. For many centuries it has personified both good and evil.",
    "material": "Холст, масло / Oil on canvas",
    "updated": 1653978309633,
    "authorRu": "Юлия Кудина",
    "titleRu": "Яблоко",
    "height": 20,
    "isSold": false,
    "titleEn": "Apple",
    "size": "20 x 15",
    "id": 81,
    "authorEn": "Julia Kudina",
    "materialRu": "Холст, масло",
    "hasNoId": false,
    "src": "apple",
  },
  // {
  //   "isSold": false,
  //   "size": "30 x 30",
  //   "slug": "birch-grove",
  //   "hasNoId": false,
  //   "src": "birch-grove",
  //   "authorRu": "Юлия Кудина",
  //   "materialEn": "Oil on canvas",
  //   "price": 18000,
  //   "descriptionRu": "Береза – это национальный русский символ. Вне зависимости от эпох и правителей, это дерево всегда находило свое место в культуре: в песнях, стихах, прозе, фильмах и картинах. Живописность березовых рощ всегда привлекала русских художников. От картины так и веет настоящим русским духом!",
  //   "year": 2019,
  //   "descriptionEn": "Birch is a national Russian symbol. Regardless of the epochs and rulers, this tree has always found its place in culture: in songs, poems, prose, films and paintings. The picturesqueness of birch groves has always attracted Russian artists. The picture breathes a real Russian spirit!",
  //   "titleEn": "Birch Grove",
  //   "width": 30,
  //   "seriesId": 10,
  //   "materialRu": "Холст, масло",
  //   "material": "Холст, масло / Oil on canvas",
  //   "titleRu": "Березовая роща",
  //   "authorEn": "Julia Kudina",
  //   "height": 30,
  //   "id": 83,
  //   "updated": 1653978280031,
  // },
  {
    "materialEn": "Oil on canvas",
    "year": 2019,
    "width": 31,
    "material": "Холст, масло / Oil on canvas",
    "descriptionRu": "Ароматная, спелая, сочная и такая вкусная груша – это фрукт, который действительно нравится многим.Такая красотка сможет подойти к любому интерьеру в вашем доме и будет напоминать о вкусе жаркого лета.",
    "authorRu": "Юлия Кудина",
    "hasNoId": false,
    "titleEn": "Pears",
    "seriesId": 6,
    "titleRu": "Груши",
    "authorEn": "Julia Kudina",
    "isSold": false,
    "price": 7000,
    "src": "pears",
    "size": "27 x 31",
    "id": 84,
    "updated": 1653978212023,
    "height": 27,
    "slug": "pears",
    "materialRu": "Холст, масло",
    "descriptionEn": "Birch is a national Russian symbol. This tree has always found its place in culture: in songs, poems, prose, films and paintings. The scenic beauty of birch groves has always attracted Russian artists. The picture is so full of the real Russian spirit!",
  },
  // {
  //   "materialEn": "Oil on canvas",
  //   "materialRu": "Холст, масло",
  //   "id": 87,
  //   "width": 40,
  //   "slug": "kuban-shore",
  //   "titleEn": "Kuban Shore",
  //   "descriptionEn": "The nature of the native land is rich. In this painting, the artist reveals all the beauty of the forest, which spreads over the expanses of the earth and borders on the coastline of the sprawling, wide and beautiful Kuban River.",
  //   "size": "30 x 40",
  //   "src": "kuban-shore",
  //   "year": 2019,
  //   "material": "Холст, масло / Oil on canvas",
  //   "isSold": false,
  //   "authorEn": "Julia Kudina",
  //   "updated": 1653978144504,
  //   "authorRu": "Юлия Кудина",
  //   "titleRu": "Берег Кубани",
  //   "price": 5000,
  //   "seriesId": 10,
  //   "descriptionRu": "Природа родного края богата и разнообразна. В данной картине, художник максимально раскрывает всю красоту леса, который раскинулся на просторах земли и граничит с береговой линией раскидистой, широкой и красивой реки Кубань.",
  //   "hasNoId": false,
  //   "height": 30,
  // },
  // {
  //   "price": 5000,
  //   "material": "Холст, масло / Oil on canvas",
  //   "materialRu": "Холст, масло",
  //   "seriesId": 10,
  //   "titleEn": "Lake",
  //   "hasNoId": false,
  //   "year": 2019,
  //   "materialEn": "Oil on canvas",
  //   "isSold": false,
  //   "slug": "lake",
  //   "updated": 1653978107567,
  //   "size": "20 x 30",
  //   "id": 88,
  //   "width": 30,
  //   "authorRu": "Юлия Кудина",
  //   "src": "lake",
  //   "descriptionEn": "The foreground is blurred in the depths of the lake. The trees are very close and densely arranged, as if squeezing it into a kind of ring. Everything is accurately and scrupulously noticed by the author, which involuntarily surprises you. The predominance of dark tones makes it possible to conclude that it is evening time. The artist wanted to show all the primevalness and virginity of nature, filling the forest lake with peace and quiet.",
  //   "height": 20,
  //   "descriptionRu": "Передний план расплывается в глубине озера. Деревья очень близко и плотно расположены, словно сжимая его в своеобразное кольцо. Все точно и скрупулёзно подмечено автором, что невольно удивляешься этому. Преобладание тёмных тонов, даёт возможность сделать вывод, что это вечернее время. Художник хотел показать всю первозданность и девственность природы, наполняющую тишиной и покоем лесное озеро.",
  //   "titleRu": "Озеро",
  //   "authorEn": "Julia Kudina",
  // },
  {
    "height": 35,
    "src": "viburnum-bouquet",
    "price": 0,
    "materialEn": "Oil on canvas",
    "material": "Холст, масло / Oil on canvas",
    "hasNoId": false,
    "titleRu": "Букет с калиной",
    "id": 91,
    "descriptionRu": "От картина так и веет цветущей женственностью и невинностью.Насыщенные краски погружают в атмосферу природной красоты и кусочка жизни на холсте.",
    "isSold": true,
    "descriptionEn": "The painting breathes of blooming beauty and innocence. Saturated colors immerse you in the atmosphere of natural glory and it feels like a piece of life on canvas.",
    "width": 45,
    "materialRu": "Холст, масло",
    "year": 2019,
    "seriesId": 4,
    "authorRu": "Юлия Кудина",
    "updated": 1653977746644,
    "slug": "viburnum-bouquet",
    "size": "35 x 45",
    "titleEn": "Viburnum Bouquet",
    "authorEn": "Julia Kudina",
  },
  {
    "height": 50,
    "hasNoId": false,
    "slug": "spring-colors",
    "price": 30000,
    "material": "Холст, масло / Oil on canvas",
    "seriesId": 4,
    "descriptionEn": "Spring is a wonderful time of blossoming nature, full of bright colors and captivating aromas. The rebirth of life, which nature was so impatiently waiting for in the long winter months",
    "materialEn": "Oil on canvas",
    "width": 70,
    "materialRu": "Холст, масло",
    "titleEn": "Spring Colors",
    "authorEn": "Julia Kudina",
    "updated": 1653978033841,
    "src": "spring-colors",
    "id": 92,
    "year": 2020,
    "authorRu": "Юлия Кудина",
    "titleRu": "Краски весны",
    "size": "50 x 70",
    "descriptionRu": "Весна - это чудесное время расцветающей природы, полной ярких красок и пленительных ароматов. Возрождение жизни, которое с таким нетерпением ожидала природа в долгие зимние месяцы",
    "isSold": false,
  },
  {
    "authorRu": "Юлия Кудина",
    "descriptionRu": "",
    "height": 50,
    "price": 0,
    "width": 50,
    "size": "50 x 50",
    "materialRu": "Холст, масло",
    "isSold": true,
    "hasNoId": false,
    "titleEn": "Aphrodite",
    "authorEn": "Julia Kudina",
    "materialEn": "Oil on canvas",
    "seriesId": 11,
    "descriptionEn": "",
    "src": "aphrodite",
    "slug": "aphrodite",
    "titleRu": "Афродита",
    "id": 96,
    "updated": 1653978013594,
    "material": "Холст, масло / Oil on canvas",
    "year": 2012,
  },
  {
    "seriesId": 3,
    "isSold": false,
    "width": 70,
    "materialRu": "Холст, масло",
    "id": 97,
    "price": 40000,
    "hasNoId": false,
    "material": "Холст, масло / Oil on canvas",
    "src": "amazons",
    "materialEn": "Oil on canvas",
    "descriptionEn": "4 women... modern Amazons? The same energetic, determined, free and beautiful. In their image, you can trace a note of tenderness and femininity. In the subdued light, these light, peaceful smiles, and graceful gestures reveal their feminine energy: they are their strength and sources. Or are these the same mysterious Amazons who once lived on the territory of the Kuban, resting after a crushing victory over the enemy? What do you see?",
    "titleEn": "Amazons",
    "height": 50,
    "year": 2011,
    "updated": 1653977955898,
    "descriptionRu": "4 женщины… современные амазонки? Такие же энергичные, решительные, свободные и прекрасные. В их образе можно проследить нотку нежности и женственности. В приглушенном свете, эти легкие, умиротворенные улыбки, и изящные жесты раскрывают их женскую энергию: они их сила и источники. Или же это те самые таинственные амазонки, жившие на территории Кубани когда-то, отдыхают после сокрушительной победы над врагом? Что видите Вы?",
    "size": "50 x 70",
    "authorRu": "Юлия Кудина",
    "titleRu": "Амазонки",
    "slug": "amazons",
    "authorEn": "Julia Kudina",
  },
  {
    "authorEn": "Julia Kudina",
    "titleRu": "Энергия Инь",
    "width": 50,
    "isSold": false,
    "descriptionEn": "Irises are not only beautiful but also symbolic flowers. In the countries of the East, they represent courage, in China, it is a symbol of mutual love and happiness. The author transferred all the richness of colour and texture of this beautiful and elegant flower.",
    "hasNoId": false,
    "year": 2020,
    "materialRu": "Холст, масло",
    "authorRu": "Юлия Кудина",
    "src": "yin-energy",
    "seriesId": 4,
    "materialEn": "Oil on canvas",
    "size": "70 x 50",
    "id": 99,
    "price": 35000,
    "titleEn": "Yin Energy",
    "slug": "yin-energy",
    "descriptionRu": "Ирисы - это не только красивые, но и символические цветы. В странах Востока они олицетворяют мужество, в Китае это символ взаимной любви и счастья. Автор передал все богатство цвета и фактуры этого красивого и элегантного цветка.",
    "height": 70,
    "updated": 1653977922328,
    "material": "Холст, масло / Oil on canvas",
  },
  {
    "updated": 1653977815921,
    "titleEn": "Mysterious Whisper",
    "src": "mysterious-whisper",
    "titleRu": "Таинственный шепот",
    "authorEn": "Julia Kudina",
    "height": 30,
    "material": "Холст, масло / Oil on canvas",
    "materialRu": "Холст, масло",
    "width": 40,
    "slug": "mysterious-whisper",
    "seriesId": 7,
    "descriptionEn": "Symbols and signs, each symbol whispers its own story to us...",
    "price": 20000,
    "isSold": false,
    "authorRu": "Юлия Кудина",
    "id": 100,
    "size": "30 х 40",
    "descriptionRu": "Символы и знаки, каждый символ нашептывает нам свою историю...",
    "year": 2020,
    "hasNoId": false,
    "materialEn": "Oil on canvas",
  },
  // {
  //   "material": "Холст, масло / Oil on canvas",
  //   "materialRu": "Холст, масло",
  //   "size": "30 х 30",
  //   "isSold": false,
  //   "src": "vast",
  //   "titleRu": "Простор",
  //   "titleEn": "Vast",
  //   "price": 15000,
  //   "id": 101,
  //   "descriptionRu": "Какого цвета небо на самом деле? для каждого, кто поднимет голову и посмотрит на него, небо своего неповторимого оттенка... голубое, розовое, сиреневое.... Простор для воображения.",
  //   "hasNoId": false,
  //   "year": 2019,
  //   "authorRu": "Юлия Кудина",
  //   "materialEn": "Oil on canvas",
  //   "updated": 1653977563410,
  //   "height": 30,
  //   "width": 30,
  //   "slug": "vast",
  //   "seriesId": 10,
  //   "descriptionEn": "What color  the sky is ? For anyone who raises his head and looks at it, the sky has its own unique shade... blue, pink, lilac.... Use your imagination",
  //   "authorEn": "Julia Kudina",
  // },
  {
    "size": "100 х 100",
    "authorRu": "Юлия Кудина",
    "titleEn": "Solar Vision",
    "width": 100,
    "authorEn": "Julia Kudina",
    "height": 100,
    "materialRu": "Холст, масло",
    "descriptionEn": "The moment when the bright sun shines through the window, and the gray unremarkable room turns into a game of sunlight.",
    "isSold": false,
    "src": "solar-vision",
    "materialEn": "Oil on canvas",
    "hasNoId": false,
    "titleRu": "Солнечное видение",
    "updated": 1653977644965,
    "price": 100000,
    "seriesId": 7,
    "slug": "solar-vision",
    "year": 2021,
    "material": "Холст, масло / Oil on canvas",
    "descriptionRu": "Момент, когда светит яркое солнце в окно, и серая ничем не примечательная комната превращается в игру солнечных лучей.",
    "id": 102,
  },
  // {
  //   "src": "reflection",
  //   "width": 30,
  //   "price": 20000,
  //   "seriesId": 10,
  //   "authorRu": "Юлия Кудина",
  //   "hasNoId": false,
  //   "id": 103,
  //   "year": 2020,
  //   "materialRu": "Холст, масло",
  //   "isSold": false,
  //   "materialEn": "Oil on canvas",
  //   "size": "20 х 30",
  //   "descriptionRu": "Теплый летний день, прогулка на берегу озера, невероятная красота природы и ее отражение в прозрачной воде. Центральным элементом картины являются белые парусники. На берегу стоит небольшой домик. Хочется смотреть на эту картину снова и снова...",
  //   "titleRu": "Отражение",
  //   "authorEn": "Julia Kudina",
  //   "slug": "reflection",
  //   "updated": 1653977372884,
  //   "material": "Холст, масло / Oil on canvas",
  //   "titleEn": "Reflection",
  //   "height": 20,
  //   "descriptionEn": "A warm summer day, a walk on the shore of the lake, the incredible beauty of nature and its reflection in the clear water. The central element of the painting is white sailboats. There is a small house on the shore. Somehow there is a desire to look at this picture again and again...",
  // },
  {
    "height": 70,
    "seriesId": 7,
    "updated": 1653977343381,
    "titleEn": "Voice of the Ancestors",
    "hasNoId": false,
    "authorEn": "Julia Kudina",
    "width": 50,
    "year": 2020,
    "descriptionRu": "О чем нам пытаются сказать предки? Предостеречь или направить на правильный путь? ...",
    "size": "70 х 50",
    "id": 105,
    "isSold": false,
    "material": "Холст, масло / Oil on canvas",
    "titleRu": "Голос предков",
    "materialRu": "Холст, масло",
    "descriptionEn": "What our ancestors are trying to tell us? Do they want to warn us about something or direct  to the right path?...",
    "price": 50000,
    "materialEn": "Oil on canvas",
    "src": "voice-of-the-ancestors",
    "slug": "voice-of-the-ancestors",
    "authorRu": "Юлия Кудина",
  },
  {
    "descriptionRu": "Всю жизнь мы чего-то ждем... может просто посмотреть вокруг и понять что ожидаемое уже рядом....",
    "authorRu": "Юлия Кудина",
    "authorEn": "Julia Kudina",
    "height": 45,
    "slug": "waiting",
    "updated": 1653977315104,
    "year": 2020,
    "materialRu": "Холст, масло",
    "material": "Холст, масло / Oil on canvas",
    "size": "45 х 35",
    "price": 40000,
    "titleRu": "В ожидании",
    "titleEn": "Waiting",
    "id": 106,
    "materialEn": "Oil on canvas",
    "src": "waiting",
    "isSold": false,
    "seriesId": 11,
    "width": 35,
    "descriptionEn": "All our life we have been waiting for something... maybe It's better to look around and realize that the one that you are waiting for is already right here...",
    "hasNoId": false,
  },
  {
    "slug": "angel",
    "price": 150000,
    "descriptionRu": "Филосовская работа, разные сюжеты которой сливаются в один. Путь от юности и беспечности к прозрению и мудрости, которые приходят с возрастом. И каждый период прекрасен своими эмоциями и чувствами... Ангел вверху картины символичен. Он - гармония возраста и душевных качеств, олицетворение опыта и насыщенности жизни... Ведь, чем старше человек, тем интереснее его жизнь.. А что видите вы, когда смотрте на эту завораживающую картину?",
    "authorEn": "Julia Kudina",
    "updated": 1653977277036,
    "year": 2020,
    "src": "angel",
    "seriesId": 11,
    "size": "120 х 105",
    "hasNoId": false,
    "id": 107,
    "height": 120,
    "width": 105,
    "descriptionEn": "A philosophical piece of art where the different plots merge into one. The path from youth and carelessness to the insight and wisdom that come with age. And each period is beautiful with its emotions and feelings. The angel at the top of the picture is symbolic. He is the harmony of age and spiritual qualities, the personification of experience and fullness of life... After all, the older a person is, the more interesting his life is... And what do you see when you look at this fascinating picture?",
    "materialRu": "Холст, масло",
    "titleEn": "Angel",
    "materialEn": "Oil on canvas",
    "material": "Холст, масло / Oil on canvas",
    "isSold": false,
    "authorRu": "Юлия Кудина",
    "titleRu": "Ангел",
  },
  {
    "isSold": false,
    "materialRu": "Холст, масло",
    "titleEn": "Bachelorette Party",
    "height": 40,
    "width": 30,
    "updated": 1653977244332,
    "year": 2020,
    "material": "Холст, масло / Oil on canvas",
    "descriptionEn": "Away from prying eyes, you can share your innermost secrets with your closest friends.",
    "slug": "bachelorette-party",
    "id": 113,
    "authorRu": "Юлия Кудина",
    "descriptionRu": "Скрывшись от чужих глаз, близким кругом подруг можно поделиться сокровенным.",
    "hasNoId": false,
    "src": "bachelorette-party",
    "seriesId": 3,
    "materialEn": "Oil on canvas",
    "size": "40 х 30",
    "price": 30000,
    "titleRu": "Девичник",
    "authorEn": "Julia Kudina",
  },
  {
    "id": 0,
    "seriesId": 10,
    "isSold": false,
    "hasNoId": true,
    "slug": "infinity",
    "src": "infinity",
    "material": "Холст, масло / Oil on canvas",
    "materialRu": "Холст, масло",
    "materialEn": "Oil on canvas",
    "titleRu": "Вечность",
    "titleEn": "Infinity",
    "authorRu": "Юлия Кудина",
    "authorEn": "Julia Kudina",
    "height": 70,
    "width": 80,
    "updated": 1653977244332,
    "year": 2022,
    "descriptionEn": "",
    "descriptionRu": "",
    "size": "70 х 80",
    "price": 100000,
  },
  {
    "id": 0,
    "seriesId": 10,
    "isSold": false,
    "hasNoId": true,
    "slug": "house-by-the-sea",
    "src": "house-by-the-sea",
    "material": "Холст, масло / Oil on canvas",
    "materialRu": "Холст, масло",
    "materialEn": "Oil on canvas",
    "titleRu": "Домик у моря",
    "titleEn": "House by the sea",
    "authorRu": "Юлия Кудина",
    "authorEn": "Julia Kudina",
    "height": 50,
    "width": 90,
    "updated": 1653977244332,
    "year": 2022,
    "descriptionEn": "",
    "descriptionRu": "",
    "size": "50 х 90",
    "price": 60000,
  },
  {
    "id": 0,
    "seriesId": 10,
    "isSold": false,
    "hasNoId": true,
    "slug": "light",
    "src": "light",
    "material": "Картон, масло / Oil on сardboard",
    "materialRu": "Картон, масло",
    "materialEn": "Oil on сardboard",
    "titleRu": "Свет",
    "titleEn": "Light",
    "authorRu": "Юлия Кудина",
    "authorEn": "Julia Kudina",
    "height": 24,
    "width": 11,
    "updated": 1653977244332,
    "year": 2022,
    "descriptionEn": "",
    "descriptionRu": "",
    "size": "24 х 11",
    "price": 3000,
  },
  {
    "id": 0,
    "seriesId": 10,
    "isSold": false,
    "hasNoId": true,
    "slug": "mountains",
    "src": "mountains",
    "material": "Холст, масло / Oil on canvas",
    "materialRu": "Холст, масло",
    "materialEn": "Oil on canvas",
    "titleRu": "Горы",
    "titleEn": "Mountains",
    "authorRu": "Юлия Кудина",
    "authorEn": "Julia Kudina",
    "height": 20,
    "width": 24.5,
    "updated": 1653977244332,
    "year": 2021,
    "descriptionEn": "",
    "descriptionRu": "",
    "size": "20 х 24.5",
    "price": 4000,
  },
  {
    "id": 0,
    "seriesId": 10,
    "isSold": false,
    "hasNoId": true,
    "slug": "bayda",
    "src": "bayda",
    "material": "Холст, масло / Oil on canvas",
    "materialRu": "Холст, масло",
    "materialEn": "Oil on canvas",
    "titleRu": "Байда",
    "titleEn": "Bayda",
    "authorRu": "Юлия Кудина",
    "authorEn": "Julia Kudina",
    "height": 40,
    "width": 40,
    "updated": 1653977244332,
    "year": 2022,
    "descriptionEn": "",
    "descriptionRu": "",
    "size": "40 х 40",
    "price": 30000,
  },
  {
    "id": 0,
    "seriesId": 10,
    "isSold": false,
    "hasNoId": true,
    "slug": "far-away",
    "src": "far-away",
    "material": "Холст, масло / Oil on canvas",
    "materialRu": "Холст, масло",
    "materialEn": "Oil on canvas",
    "titleRu": "Вдали",
    "titleEn": "Far away",
    "authorRu": "Юлия Кудина",
    "authorEn": "Julia Kudina",
    "height": 55,
    "width": 40,
    "updated": 1653977244332,
    "year": 2022,
    "descriptionEn": "",
    "descriptionRu": "",
    "size": "55 х 40",
    "price": 40000,
  },
  {
    "id": 0,
    "seriesId": 10,
    "isSold": false,
    "hasNoId": true,
    "slug": "autumn-in-russia",
    "src": "autumn-in-russia",
    "material": "Картон, масло / Oil on сardboard",
    "materialRu": "Картон, масло",
    "materialEn": "Oil on сardboard",
    "titleRu": "Осень в России",
    "titleEn": "Autumn in Russia",
    "authorRu": "Юлия Кудина",
    "authorEn": "Julia Kudina",
    "height": 19,
    "width": 20,
    "updated": 1653977244332,
    "year": 2022,
    "descriptionEn": "",
    "descriptionRu": "",
    "size": "19 х 20",
    "price": 5000,
  },
  {
    "id": 0,
    "seriesId": 10,
    "isSold": false,
    "hasNoId": true,
    "slug": "assumption-cathedral",
    "src": "assumption-cathedral",
    "material": "Холст, масло / Oil on canvas",
    "materialRu": "Холст, масло",
    "materialEn": "Oil on canvas",
    "titleRu": "Успенский собор",
    "titleEn": "Assumption Cathedral",
    "authorRu": "Юлия Кудина",
    "authorEn": "Julia Kudina",
    "height": 60,
    "width": 40,
    "updated": 1653977244332,
    "year": 2022,
    "descriptionEn": "",
    "descriptionRu": "",
    "size": "60 х 40",
    "price": 40000,
  },
  {
    "id": 0,
    "seriesId": 10,
    "isSold": false,
    "hasNoId": true,
    "slug": "crimea-mysovoe",
    "src": "crimea-mysovoe",
    "material": "Холст, масло / Oil on canvas",
    "materialRu": "Холст, масло",
    "materialEn": "Oil on canvas",
    "titleRu": "Крым. Мысовое",
    "titleEn": "Crimea. Mysovoe",
    "authorRu": "Юлия Кудина",
    "authorEn": "Julia Kudina",
    "height": 29,
    "width": 14,
    "updated": 1653977244332,
    "year": 2022,
    "descriptionEn": "",
    "descriptionRu": "",
    "size": "29 х 14",
    "price": 20000,
  }
]